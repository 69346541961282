import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Field} from "react-final-form";
import {FiCheck} from "react-icons/fi";
import EnigooToolTip from "./EnigooToolTip";

const EnigooRadioField = ({
                              items,
                              defaultValues = ["id", "name", "label", "active", "toolTip"],
                              columns = 4,
                              name,
                              label = "",
                              isMulti = false,
                              disabled = false
                          }) => {

    let [radioData, setRadioData] = useState([]);

    useEffect(() => {
        let data = [];
        items.forEach((item, index) => {
            data.push({
                value: item[defaultValues[0]],
                title: item[defaultValues[1]],
                label: item[defaultValues[2]],
                active: item[defaultValues[3]],
                toolTip: item[defaultValues[4]]??null
            })
        })

        setRadioData(data);
    }, [items]);

    const onClick = (input, item) => {
        if (!disabled && (item.active !== false)) {
            if (isMulti) {
                let newArrayValue = [];
                if (!isSelected(input, item)) {
                    newArrayValue = [...input.value, item.value];
                } else {
                    input.value.forEach((val) => {
                        if (val !== item.value) {
                            newArrayValue.push(val);
                        }
                    })
                }
                input.onChange({
                    target: {
                        value: newArrayValue,
                        type: "change",
                        name: name
                    }
                })
            } else {
                let newValue = isSelected(input, item) ? null : item.value;
                input.onChange({
                    target: {
                        value: newValue,
                        type: "change",
                        name: name
                    }
                })
            }
        }

    }

    const isSelected = (input, item) => {
        if (isMulti) {
            return input.value.includes(item.value);
        } else {
            return item.value === input.value;
        }
    }

    const renderStyle = (input, item) => {
        if (disabled || (item.active === false)) {
            if (isSelected(input, item)) {
                return "cursor-not-allowed border-enigoo-100 bg-enigoo-100 text-grey-50";
            } else {
                return "cursor-not-allowed border-enigoo-100 bg-grey-50 text-enigoo-100";
            }
        } else {
            if (isSelected(input, item)) {
                return "cursor-pointer border-enigoo-500 bg-enigoo-500 text-grey-50 hover:-translate-y-1 hover:scale-75 hover:bg-enigoo-500 hover:text-grey-50 hover:font-bold delay-75 duration-100";
            } else {
                return "cursor-pointer border-enigoo-500 bg-grey-50 text-enigoo-500 hover:-translate-y-1 hover:scale-75 hover:bg-enigoo-500 hover:text-grey-50 hover:font-bold delay-75 duration-100";
            }
        }
    }

    return <div className={"form-element"}>
        <div className={"form-label"}>{label}</div>
        <Field name={name}>
            {({input, meta}) => (
                <>
                    <div
                        className={`${meta.error && " border rounded border-red-500"} p-1 grid lg:grid-cols-${columns} grid-cols-${Math.round(columns / 2)} gap-2`}>
                        {radioData.map((item, index) => (
                                <>
                                    <div data-tip data-for={"_tool_tip_rf_"+name+"_"+index}
                                         className={`p-1 flex flex-col justify-center w-full space-y-1 rounded border-2 ${renderStyle(input, item)} relative`}
                                         key={"name_"+index}
                                         onClick={() => {
                                             onClick(input, item)
                                         }}>
                                        {isSelected(input,item)&&<div className={"absolute top-0 right-0 m-2"}> <FiCheck size={20}/></div>}

                                        <div className={"text-center"}>{item.title}</div>
                                        {item.label && <div className={"italic text-xs text-center"}>{item.label}</div>}
                                    </div>
                                    {item.toolTip && <EnigooToolTip id={"_tool_tip_rf_"+name+"_"+index} content={item.toolTip} place={"right"}/>}
                                </>
                            )
                        )}
                    </div>
                    {meta.error && <div className="form-error">{meta.error}</div>}
                </>
            )}
        </Field>
    </div>


}
EnigooRadioField.propTypes = {
    items: PropTypes.array.isRequired,
    defaultValues: PropTypes.array,
    isMulti: PropTypes.bool,
    columns: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool

}
export default EnigooRadioField;
